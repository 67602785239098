import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { ClearButton } from '@trexity/common-client/lib/components'
import Config from '../config'

const LayoutDevelopers = ({
  children,
  isLandingPage = false,
  legacy = false
}) => {
  const [active, setActive] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [scrolledBottom, setScrolledBottom] = useState(false)
  const [showApiVersionsMenu, setShowApiVersionsMenu] = React.useState(false)

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      const isScrolledBottom = (window.scrollY + window.innerHeight >= document.body.scrollHeight)

      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }

      setScrolledBottom(isScrolledBottom)
    }

    if (typeof document !== 'undefined') {
      document.addEventListener('scroll', handleScroll, { passive: true })
    }

    return () => {
      if (typeof document !== 'undefined') {
        // clean up the event handler when the component unmounts
        document.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrolled])

  return (
    <div id='top' className={`page ${!isLandingPage ? 'developers' : ' '}${legacy ? 'legacy' : ' '}`}>
      <Helmet>
        <title>Trexity</title>
        <link rel='stylesheet' href='https://use.typekit.net/dtd2ale.css' />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

        {/* Google Maps API */}
        <meta name="google-site-verification" content="c2vkcvBIc1eJgVtclBtjglN1sKahSYsggp8mB5MAXq8" />

        {/* Essential META Tags */}
        <meta property="og:title" content="Delight your local customers with Trexity same-day delivery." />
        <meta property="og:description" content="With a flexible, scalable, and cost-effective driver fleet at your command, the new standard of door-to-door service is yours." />
        <meta property="og:image" content={`${Config.SITE_URL}/img/trexity-og.jpg`} />
        <meta property="og:url" content={Config.SITE_URL} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="facebook-domain-verification" content="q3eoff0xmlthg9ily6p6k8tt0yrbny" />

        {/* Non-Essential, But Recommended */}
        <meta property="og:site_name" content="Delight your local customers with Trexity same-day delivery." />
        <meta name="twitter:image:alt" content="Person with laptop and delivery confirmation" />

        {/* Non-Essential, But Required for Analytics
        <meta property="fb:app_id" content="your_app_id" />
        <meta name="twitter:site" content="@website-username" /> */}
      </Helmet>

      {active ? (
        <div className='nav-overlay' onClick={() => { setActive(false) }}></div>
      ) : null}

      <header id='main-nav' className={[scrolled && 'active'].filter(Boolean).join(' ')}>
        <section>
          <div className={active ? 'active' : ''}>
            <Link to='/' className='logo'>
              <img src='/img/trexity-logo-blue.png' alt='Trexity' />
            </Link>
          </div>
        </section>
      </header>

      <div id="body" className={scrolledBottom ? 'scrolled-bottom' : null}>
        {children}
      </div>

      <footer id="main-footer">
        <section className='no-margin'>
          <div className="row equal">
            <div className="col">
              <div className="inner">
                <h5>Information</h5>
                <ul>
                  <li><Link to='/merchants/' activeClassName="active">Merchants</Link></li>
                  <li><Link to='/pricing/' activeClassName="active">Pricing</Link></li>
                  <li><Link to='/partners/' activeClassName="active">Partners</Link></li>
                  <li><Link to='/drivers/' activeClassName="active">Drivers</Link></li>
                  <li><Link to='/covid19/' activeClassName="active">Covid-19</Link></li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="inner">
                <h5>Trexity</h5>
                <ul>
                  <li><Link to={Config.ZONES_PAGE_REDIRECT_URL} target='_blank' activeClassName="active">Zones</Link></li>
                  <li><Link to='/about/' activeClassName="active">About</Link></li>
                  <li><Link to='/press/' activeClassName="active">Press</Link></li>
                  <li><Link to='/careers/' activeClassName="active">Careers</Link></li>
                  <li><Link to='/contact/' activeClassName="active">Contact</Link></li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="inner">
                <h5>Support</h5>
                <ul>
                  <li><Link to='/developers/' activeClassName="active">Developers</Link></li>
                  <li><a target='blank' href='https://trexity.zendesk.com/hc/en-us'>Knowledge Center</a></li>
                  <li><Link to='/privacy-policy/' activeClassName="active">Privacy Policy</Link></li>
                  <li><Link to='/terms-of-service/' activeClassName="active">Terms of Service</Link></li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="inner">
                <h5>Trexity Driver App</h5>
                <p>Your personal delivery assistant. Download to start driving.</p>
                <div className="app-store">
                  <a href="https://apps.apple.com/ca/app/trexity-driver/id1479475710"><img src="/img/store-apple.png" alt="Download on the App Store" /></a>
                  <a href="https://play.google.com/store/apps/details?id=com.trexity.drivermobile"><img src="/img/store-google.png" alt="Get it on Google Play" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>

      <div className="secondary-footer">
        <section>
          <div className="inner">
            <ul className="social">
              <li><a href="https://www.instagram.com/trexityapp/" target="_blank" rel="noreferrer"><img src="/img/social-instagram.png" alt="Instagram" /></a></li>
              <li><a href="https://www.linkedin.com/company/trexity/about/" target="_blank" rel="noreferrer"><img src="/img/social-linkedin.png" alt="Linkedin" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCgPSAqwQf-VjoxEY782_IYA" target="_blank" rel="noreferrer"><img src="/img/social-youtube.png" alt="Youtube" /></a></li>
            </ul>
            <a href='#top' className='back-to-top'>Back to top <i className="material-icons">north</i></a>
          </div>
        </section>
      </div>
    </div>
  )
}

LayoutDevelopers.propTypes = {
  children: PropTypes.node,
  isLandingPage: PropTypes.bool,
  legacy: PropTypes.bool
}

export default LayoutDevelopers
